.footer-container {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    
    opacity: 0.6;
}

.inner-container {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;

    width: 33%;
}

.inner-container-text {
    margin-right: 5px;
    margin-left: 5px;
}

.spaced {
    letter-spacing: 2px;
    font-weight: 800;
}

.footer-text {
    font-size: 11px;
    line-height: 200%;
    font-weight: 500;
}