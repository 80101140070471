/* Top Intro */
.card-container {
    position: relative;
    text-align: left;
}

.card-container *:not(.face) {
    position: relative;
    z-index: 1; /* Ensures all other content is above the image */
}

.landing-uni {
    display: inline-block;
}

.blocky {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 900;
}

.loose {
    letter-spacing: 2px;
    font-weight: 400;
}

.credly {
    height: 70px;
    margin-left: 20px;
    top: 20px;
}


/* LINKS SECTION */

@media (max-width: 350) {
    .landing-name {
        font-size: 20vw;
    }
} 

/* LINKS */
.button-flex {
    display: inline-flex;
    flex-direction: row;

}