.industries-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.industry-flex {
    max-width: 100px;
    min-width: 100px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    justify-content: start;
    align-items: center;
    text-align: center;

    padding-left: 0.5vw;
    padding-right: 0.5vw;
}

.industry-icon {
    height: 50px;
    min-height: 50px;

    color: #333;
}

@media (min-width: 900px) {
    .bio-paragraph {
        max-width: 55%;
        width: 40vw;
        margin: 0 auto;
        line-height: 25px;
    }
}

@media (max-width: 900px) {
    .bio-paragraph {
        max-width: 100%;
        margin: 0 !important;
        padding-bottom: 50px

    }
};

/* BUSINESS FLEX */
.business-container {
    background-color: #f8f9fb;
    border-radius: 28px;

}

.svg-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-around;
}

@media (max-width: 900px) {
    .svg-container {
        justify-content: center;
        align-items: center;
    }

    .job-logo {
        width: 80% !important;
    }

    .cvlinkedin-flex {
        margin-top: 50px;
    }
}

.business {
    height: auto;
    width: 70px;

    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;

    filter: grayscale(100%);
    opacity: 0.7;
}


/* ICONS */
.bio-icon--arrow {
    padding-top: 3px;
}

.bio2-icon--briefcase {
    color: #1967d2;
}