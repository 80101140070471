.projects-container {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    
    background-color: #f8f9fb;
}

.rocket {
    color: #407cdc;
}