/* TOP LEVEL CONTAINER */
.job-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    width: 100%;
}

/* Extra large devices (default) */
.job-container {
    display: flex;
    justify-content: flex-start;

    border: none;

    margin: 0 auto;
}

.company-width {
    max-width: 40%;
}

.job-skills {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
}

@media (max-width:1200px) {
    .job-container {
        display: flex;
        justify-content: flex-start;

        margin-bottom: 5vh;

        width: 100%;
        margin: 0 auto;
    }
    
    .global-section {
        padding-left: 5vw !important;
        padding-right: 5vw !important;
    }

    .job-title {
        font-size: 10vw;
        text-align: center;
    }

}

/* TITLES AND INFO */
.job-name {
    display: inline-block;
    transition: color 0.15s ease-in-out;
    width: 100%;
}

.job-website {
    display: inline-block;
}

.job-logo {
    width: 15vw;
    height: 100%;
}

/* JOB LISTS AND ITEMS*/

/* METRICS */
.metric-container {
    border-radius: 28px;
}

.job-metrics {
    padding: 0;
}

.metric-title {
    text-align: left;
    width: 100%;
}

.metric-dropdown {
    border-radius: 4px;
    background-color: #e4f5fd;
    margin-bottom: 20px;
}

.metric-bulletpoint {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.job-metric-description {
    width: 100%;
    text-align: left;
}


/* ARROW ICON */

.icon-white {
    padding-bottom: 10px;
    margin-top: 10px;
    height: 20px;

    transition: color 0.15s ease-in-out;
}

/* SKILLS ARRAY AND BUBBLES */

.job-skills {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.skill {
    display: inline-block;
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 auto;
    
}

.skills-outer {
    text-align: left;
    width: 100%;
}

.soft-skill, .hard-skill {
    border-radius: 8px;
    margin-left: 20px;
}

.hard-skill {
    background-color: #0d272c;
    color: #189dc4;
}

.hard-skill:hover {
    background-color: #17464f;
    color: #dfdfdf;
}

.soft-skill {
    background-color: #043624;
    color: #13d284;
}

.soft-skill:hover {
    background-color: #26805f;
    color: #dfdfdf;
}

.diamond-outer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start;
}

.diamond-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.diamond {
    color: #3b3f40;
}

/* CV & LINKEDIN */
.cvlinkedin-flex {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}