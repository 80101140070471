/* Fonts */

@font-face {
    font-family: "Polysans";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/PolySansGX.woff2), format(woff2);
}

@font-face {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    src: local("DM Sans"), url("../fonts/DMSans-VariableFont_opsz\,wght.ttf") format("ttf");
}

/* GLOBALS */
html, body {
    scroll-behavior: smooth;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    margin: 0;

    padding: 0;
    height: 100%;
    
    max-width: 100vw;

}

.main {
    margin: 0 auto;
    min-width: 100%;
}

h1, h2, h3, h4, h5, h6, p, b, strong, span {
    font-family: "DM Sans", sans-serif;
    
    font-optical-sizing: auto;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    font-weight: 1 1000;

    margin: 0;

    color: #272a2b;
}

.alt {
    font-family: "Polysans", "DM Sans";
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

h1 {
    font-size: 60px;
    line-height: 68px;
    font-weight: 700;
}

h2 {
    font-size: 45px;
    line-height: 50px;
    font-weight: 600;
}

h3 {
    font-size: 25px;
    line-height: normal;
    font-weight: 600;
    
    word-spacing: 2px;
}

h4 {
    font-size: 16px;
    line-height: normal;
    font-weight: 500;
    
}

h5, p {
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
}

b {
    font-weight: 800;
}

strong {
    text-transform: uppercase;
}

ul, ol {
    padding: 0;
}

li {
    margin-top: 10px;
    list-style-type: none;
}

.flex-row-standard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-column-standard {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

/* LINKS AND HOVERS */

a, a:visited {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

a:hover, a:active{
    color: #1967d2;
}

.hover, .hover * {
    color: #1967d2 I !important;
    transition: all 250ms cubic-bezier(0.1, 0.1, 0, 1);
}

.hover:hover {
    opacity: 0.8;
}

.hover2 {
    color: #1967d2;
    transition: all 0.1s ease-in-out;
    margin-bottom: 1px;
}

.hover2:hover, .hover2:active {
    text-decoration: underline;
}

.hover3:hover {
    color: #1967d2;
}

.simple-button, .simple-button--grey, .simple-button--blue, .simple-button--white, .blue-button {
    display: inline-block;
    width: auto;

    text-align: center;

    border-radius: 8px;

    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 1vh;
    padding-bottom: 1vh;

    transition: all 250ms cubic-bezier(0.1, 0.1, 0, 1);
    cursor: pointer;
}

/* SIMPLE BUTTON */

.simple-button {
    background-color: #f5f2ed;
    border: black 2px solid;
    transition: all 0.1s ease-in-out;
}

.simple-button:hover {
    background-color: rgb(233, 233, 225);
}

.simple-button--blue {
    background-color: #e4f5fd;
    border: black 2px solid;
}

.simple-button--blue:hover {
    background-color: #CBDCE4;
}

.simple-button--white {
    background-color: #fff;
    border: #ddd 1px solid;
    
    transition: all 250ms cubic-bezier(0.1, 0.1, 0, 1);
}

.simple-button--white:hover {
    background-color: #fAfAfA;
}


/* BLUE BUTTON */

.blue-button {
    background-color: #1967d2;
}

.blue-button * {
    color: white;
}

.blue-button:hover {
    background-color: #407cdc;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,.15);
}

/* SECTION & BACKGROUNDS */

.landing-background {
    background-color: #f5f2ed;
}

.bio-background {    
    background-color: #ffffff;
}

.skills-background {
    background-color: #ffffff;
}

.job-background {
    background-color: #ffffff;
}

.projects-background {
    background-color: #ffffff;
}

.blog-background {
    background-color: #ffffff;
}

.footer-background {
    background-color: #ffffff;
    border-top: 1px solid #f2f2f2;
}

.job-section {
    margin-left: -10vw !important;
    padding-left: 100 !important;
    
}

/* GLOBAL STYLES */

.global-section {
    padding-left: 25vw;
    padding-right: 25vw;
}

.global-section-shallow {
    padding-left: 10vw;
    padding-right: 10vw;
}

.white {
    color: white;
}

.shadow {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .15);;
}

.backgrounded {
    background-color: #f5f2ed;
    transition: background-color 0.15s ease-in-out;
}

.backgrounded:hover {
    background-color: #f7f5f1;
}

.seperator {
    min-width: 100%;
    border-top: 1px solid #eee;
}

.seperator-small {
    max-width: 60%;
    margin: 0 auto;
    border-top: 1px solid #eee;
}

.rounded {
    border-radius: 50px;
}

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.np-nm {
    margin: 0;
    padding: 0;

}

.large-margin-top {margin-top: 30vh;}
.large-margin-bottom {margin-bottom: 30vh;}
.medium-margin-top {margin-top: 10vh;}
.medium-margin-bottom {margin-bottom: 10vh;}
.small-margin-top {margin-top: 2vh;}
.small-margin-bottom {margin-bottom: 2vh;}

.micro-margin-top {margin-top: 5px;}
.micro-margin-bottom {margin-bottom: 5px;}

.large-padding-top {padding-top: 30vh;}
.large-padding-bottom {padding-bottom: 30vh;}
.medium-padding-top {padding-top: 10vh;}
.medium-padding-bottom {padding-bottom: 10vh;}
.small-padding-top {padding-top: 2vh;}
.small-padding-bottom {padding-bottom: 2vh;}

.micro-padding-top {padding-top: 5px;}
.micro-padding-bottom {padding-bottom: 5px;}

.right-hand-padding {padding-right: 20px;}
.right-hand-margin {margin-right: 20px;}
.left-hand-padding {padding-left: 20px;}
.left-hand-margin {margin-left: 20px;}

/* FUNCTIONALITY CSS */

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

